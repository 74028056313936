import React, { useEffect, useState } from "react";
import { Typography, Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { Progress } from "rsuite";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "../../../components/UI/Select";
import config from "../../../config/appConfig";
import DropZone from "../../../components/UI/DropZone";
import { useTheme } from "@mui/system";
import Form from "react-bootstrap/Form";
import { Button as RButton } from "rsuite";
import Back from "../../../components/UI/Back";
import { useNavigate } from "react-router-dom";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { useLocation } from "react-router-dom";
import axios from "axios";

const AddModulePage = () => {

	const theme: any = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const state = location.state;
	
	const [ selectedCourse, setSelectedCourse ] = useState<any>(null);
	const [ courseData, setCourseData ] = useState<any>(null);
	const [ courseOptions, setCourseOptions ] = useState<any>([]);
	const [ moduleName, setModuleName ] = useState<string>("");
	const [ moduleDescription, setModuleDescription ] = useState<string>("");
	const [ otherDescription, setOtherDescription ] = useState<any>("");
	const [ otherDescriptionArray, setOtherDescriptionArray ] = useState<any>([]);
	const [ modulePrice, setModulePrice ] = useState<any>(null);
	const [ modulePriceInUSD, setModulePriceInUSD ] = useState<any>(null);
	const [ uploadPostMediaName, setUploadPostMediaName ] = useState("");
	const [ isUploadedMediaValid, setIsUploadedMediaValid ] = useState(false);
	const [ isError, setIsError ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<string>("");
	const [ isEditModule, setIsEditModule ] = useState<boolean>(false);
	const [ fileDetails, setFileDetails ] = useState<any>(null);
	const [ moduleId, setModuleId ] = useState<any>(null);
	const [ uploadProgress, setUploadProgress ] = useState(0);
	const [ isFileUploading, setIsFileUploading ] = useState(false);
	const [ moduleOrder, setModuleOrder ] = useState<any>(null);

	useEffect(() => {

		fetchAllCourse();

		if (location.hash === "#edit-module" && state) {

			setModuleId(state?._id);
			setIsEditModule(true);
			setSelectedCourse({
				label: state?.courseId?.courseName,
				value: state?.courseId?._id
			});
			setModuleName(state?.moduleName);
			setModuleDescription(state?.moduleDesc);
			setModulePrice(state?.modulePrice);
			setModulePriceInUSD(state?.modulePriceInUSD);
			setModulePriceInUSD(state?.modulePriceInUSD);
			setOtherDescriptionArray(state?.otherInfo?.skillSet || []);
			setUploadPostMediaName(state?.moduleIcon?.split("/").pop());
			setModuleOrder(state?.moduleOrder);
			setIsUploadedMediaValid(true);

		}

	}, []);

	const fetchAllCourse = () => {

		ApiRequest("get", APIUrl.getAllCourse).then((response: any) => {

			if (response) {

				const options: any = [];
				response?.map((item: any) => {

					options.push({ label: item.courseName, value: item._id });

				});

				setCourseData(response);
				setCourseOptions(options);

			}

		}).catch((error: any) => {

			console.error("Error fetching data", error);

		});

	};

	const handleInputChange = (e: any) => {

		setOtherDescription(e?.target?.value);

	};

	const handleKeyDown = (e: any) => {

		if(e?.key === "Enter" && otherDescription?.trim() !== "") {

			setOtherDescriptionArray((prevDescriptionValues:any) =>[ ...prevDescriptionValues, otherDescription ]);
			setOtherDescription("");
			e.preventDefault();
		
		}
	
	};
	const handleDelete = (item: any) => () => {

		setOtherDescriptionArray((items: any) => items.filter((values: any, index: number) => index !== item));
	
	};

	const postMediaDrop = (acceptedFiles: any) => {

		if (config?.acceptedImageVideoFormats.includes(acceptedFiles[0].type)) {

			setUploadPostMediaName(acceptedFiles[0].name);
			setFileDetails(acceptedFiles[0]);
			setIsUploadedMediaValid(true);

		} else {

			console.error("File type not supported");
			setIsUploadedMediaValid(false);
			setUploadPostMediaName("File type not supported");

		}

	};

	const handleAddOrUpdateModule = () => {

		if(!selectedCourse?.value || moduleName === "" || modulePrice === "" || modulePriceInUSD === "" || !modulePrice || !modulePriceInUSD || !moduleOrder) {

			setIsError(true);
			setErrorMessage("*Fill All Required Fields");
			
			return;
			
		}

		if (isEditModule || moduleId) {

			const payload = {
				"moduleData":
				{
					courseId        : selectedCourse?.value,
					moduleOrder     : moduleOrder,
					moduleName      : moduleName,
					moduleDesc      : moduleDescription,
					modulePrice     : modulePrice,
					modulePriceInUSD: modulePriceInUSD,
					otherInfo       : { skillSet: otherDescriptionArray },
					_id             : moduleId ? moduleId : state?._id
				}
			};

			ApiRequest("post", APIUrl.updateModule, payload).then((response: any) => {

				if (response) {

					navigate(config.routes.listModules);

				}

			}).catch((error: any) => {

				console.error("Error in updating module", error);

			});
			

		} else {

			const payload = {
				"moduleData":
				{
					courseId        : selectedCourse?.value,
					moduleOrder     : moduleOrder,
					moduleName      : moduleName,
					moduleDesc      : moduleDescription,
					modulePrice     : modulePrice,
					modulePriceInUSD: modulePriceInUSD,
					otherInfo       : { skillSet: otherDescriptionArray }
				}
			};

			ApiRequest("post", APIUrl.addNewModule, payload).then((response: any) => {

				if (response) {

					navigate(config.routes.listModules);

				}

			}).catch((error: any) => {

				console.error("Error in adding module", error);

			});

		}
		setIsError(false);
		setErrorMessage("");
		setModulePrice(null);
		setModulePriceInUSD(null);

	};

	const handleClose = () => {
		
		navigate(config.routes.listModules);
		setModuleName("");
		setModuleDescription("");
		setModulePrice(null);
		setModulePriceInUSD(null);
		setOtherDescriptionArray([]);
		setOtherDescription("");

	};

	const handleUploadModuleIcon = () => {

		if (fileDetails) {

			const payload: any = {
				"moduleData": {
					"courseId"        : selectedCourse?.value,
					"moduleIcon"      : fileDetails.name,
					"iconType"        : fileDetails.type,
					"moduleName"      : moduleName,
					"moduleDesc"      : moduleDescription,
					"modulePrice"     : modulePrice,
					"modulePriceInUSD": modulePriceInUSD
				}
			};

			if (state?._id) {

				payload["moduleData"]["_id"] = state?._id;

			}

			setIsFileUploading(true);

			ApiRequest("post", APIUrl.getSignedUrlUploadModuleIcon, payload).then(async (response: any) => {

				if (response) {

					const link = response?.link;
					const moduleId = response?.id;

					setModuleId(moduleId);

					const onUploadProgress = (progressEvent: any) => {
	
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						setUploadProgress(percentCompleted);
		
					};

					await axios.put(link, fileDetails, { headers: { "Content-Type": fileDetails.type }, onUploadProgress: onUploadProgress });

					setIsFileUploading(false);

				}

			}).catch((error: any) => {

				setIsFileUploading(false);

				console.error("Error in uploading module icon", error);

			});

		}

	};


	return (
		<Container>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.listModules,
								state  : null,
								toolTip: "Back to list modules",
								text   : "Back to list modules"
							}}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Typography style={{ color: theme.palette.text.primary }} variant="h2">{ isEditModule ? "Edit Module" : "Add Module"}</Typography>
					</div>
				</Col>
			</Row>
			<Row >
				<Col className="add-module-select-course-col" >
					<Row
						style={{ marginTop: "1em" }}
						className="sh-addlesson-enter-lesson-name-page"
					>
						<Col
							className="sh-add-lesson-typography-label-page"

						>
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }}
									// style={{ alignItems: "center" }}
								>Select Course <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span>
								</Typography>
							</div>
                            
							
						</Col>
						<Col className="sh-add-lesson-input-label">
							<Select
								style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								className="sh-add-lesson-select-input-div"
								options={courseOptions}
								value={selectedCourse}
								handleChange={(e: any) => {
									
									setSelectedCourse(e);

									const courseId = e?.value;

									if (courseData) {

										const course = courseData.find((item: any) => item._id === courseId);
										setModuleOrder(course?.moduleDetails?.length + 1);

									}
								
								}}
								placeholder="Select the Course"
							/>
						</Col>
						
					</Row>
					<Row
						style={{ marginTop: "1em" }}
						className="sh-addlesson-enter-lesson-name-page sh-addlesson-enter-lesson-name-page-margin-top"
					>
						<Col
							className="sh-add-lesson-typography-label-page"
						>
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }} className="sh-add-module-multi-description-label sh-add-lesson-marginTop">Module Order <span style={{ color: theme?.palette?.error?.main }} >*</span></Typography>
							</div>
						</Col>
						<Col className="sh-add-lesson-input-label">
							<Form.Control
								className="sh-add-module-name-text-field text-field-width custom-placeholder"
								style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								id="outlined-basic"
								placeholder="Enter module order"
								/*
								 * label="Enter Module Price"
								 * variant="outlined"
								 */
								value={moduleOrder}
								// fullWidth
								type="number"
								onChange={(e: any) => setModuleOrder(e?.target?.value)}
								// size="small"
								color="secondary"
								onFocus={(e: any) => {

									try {
	
										const focusColor: string = theme.palette.secondary.main;
	
										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.fontSize = "13px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
										
									} catch (error: any) {
	
										console.error("Error on focus", error);
	
									}
								
								}}
								onBlur={(e: any) => {
	
									try {
	
										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
										
									} catch (error: any) {
	
										console.error("Error on blur", error);
	
									}
								
								}}
								
							/>
						</Col>
						
					</Row>
					<Row className="sh-addlesson-enter-lesson-name-page">
						<Col
							className="sh-add-lesson-typography-label-page"
						>
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }}>Module Name <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span>
								</Typography>
							</div>
							
						</Col>
						<Col className="sh-add-lesson-input-label">
							<Form.Control
								style={{ backgroundColor: theme?.palette?.background.paper, color: theme?.palette?.text.primary }}
								className="sh-add-module-name-text-field sh-user-basic-edit-label-div custom-placeholder"
								id="outlined-basic"
								/*
								 * label="Enter Module Name"
								 * variant="outlined"
								 */
								placeholder="Enter Module Name"
								value={moduleName}
								// fullWidth
								onChange={(e: any) => setModuleName(e?.target?.value)}
								// size="small"
								color="secondary"
								onFocus={(e: any) => {

									try {
	
										const focusColor: string = theme.palette.secondary.main;
	
										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.fontSize = "13px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
										
									} catch (error: any) {
	
										console.error("Error on focus", error);
	
									}
								
								}}
								onBlur={(e: any) => {
	
									try {
	
										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
										
									} catch (error: any) {
	
										console.error("Error on blur", error);
	
									}
								
								}}
							/>
                            
						</Col>
					</Row>
					<Row className="sh-addlesson-enter-lesson-name-page">
						<Col className="sh-add-lesson-typography-label-page">
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }} >Module Description</Typography>
							</div>
						</Col>
						<Col className="sh-add-lesson-input-label" >
							<Form.Control
								as="textarea"
								style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								rows={2}
								className="sh-add-module-description-text-area sh-add-module-font-size Module custom-placeholder"
								value={moduleDescription}
								onChange={(e: any) => {


									setModuleDescription(e?.target?.value);

								}}
								placeholder="Enter Module Description"
								onFocus={(e: any) => {

									try {

										const focusColor: string = theme.palette.secondary.main;

										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.fontSize = "13px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
										
									} catch (error) {

										console.error("Error on focus", error);

									}
							
								}}
								onBlur={(e: any) => {

									try {

										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
										
									} catch (error) {

										console.error("Error on blur", error);

									}

							
								}}
							/>

						</Col>
					</Row>
					<Row className="sh-addlesson-enter-lesson-name-page">
						<Col className="sh-add-lesson-typography-label-page">
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }} >Other Description Info</Typography>
							</div>
						</Col>
						<Col className="sh-add-lesson-input-label">
							<Form.Control as="textarea" rows={2}
								style={{
									backgroundColor: theme.palette.background.paper,
									color          : theme.palette.text.primary
							 }}
								value={otherDescription}
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder="Type and press enter to add description"
								color="secondary"
								className="sh-add-module-name-text-field sh-other-desc-input-form sh-user-basic-edit-label-div custom-placeholder"
								id="outlined-basic"
								onFocus={(e: any) => {

									try {
	
										const focusColor: string = theme.palette.secondary.main;
	
										e.target.style.borderColor = focusColor;
										e.target.style.boxShadow = `0px 0px 3px 1px ${focusColor}`;
										// e.target.style.padding = "12px";
										
									} catch (error: any) {
	
										console.error("Error on focus", error);
	
									}
								
								}}
								onBlur={(e: any) => {
	
									try {
	
										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
										
									} catch (error: any) {
	
										console.error("Error on blur", error);
	
									}
								
								}}
								
							/>
						</Col>
						<style>
							{`
							.custom-placeholder::placeholder {
								color: ${theme.palette.text.secondary};
								opacity: 1;
								font-size: 14px;
							}
							`}
						</style>
					</Row>
					<Row style={{ marginTop: "0px !important" , paddingTop: "0px" }} >
						<Col className="sh-add-lesson-typography-label-page" >
						</Col>
						<Col className="sh-add-lesson-input-label">
							<div className="sh-add-module-other-desc-label">
								{otherDescriptionArray?.length > 0 && otherDescriptionArray?.map((data: any, index: number) =>
									<div
										key={index}
										className="sh-add-module-multi-description-chip-con" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}
									>
										{data}
										<ClearIcon className="sh-add-module-multi-description-chip-close-icon" style={{ color: theme.palette.text.primary }}
											onClick={handleDelete(index)} />
									</div>
								)
								}
							</div>
						</Col>
					</Row>
					
					<Row
						style={{ marginTop: "0px" }}
						className="sh-addlesson-enter-lesson-name-page sh-addlesson-enter-lesson-name-page-margin-top"
					>
						<Col
							className="sh-add-lesson-typography-label-page"
						>
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }} className="sh-add-module-multi-description-label sh-add-lesson-marginTop">Module Price <span style={{ color: theme?.palette?.error?.main }} >*</span></Typography>
							</div>
						</Col>
						<Col className="sh-add-lesson-input-label">
							<Form.Control
								className="sh-add-module-name-text-field text-field-width custom-placeholder"
								style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								id="outlined-basic"
								placeholder="Enter Module Price"
								/*
								 * label="Enter Module Price"
								 * variant="outlined"
								 */
								value={modulePrice}
								// fullWidth
								type="number"
								onChange={(e: any) => setModulePrice(e?.target?.value)}
								// size="small"
								color="secondary"
								onFocus={(e: any) => {

									try {
	
										const focusColor: string = theme.palette.secondary.main;
	
										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.fontSize = "13px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
										
									} catch (error: any) {
	
										console.error("Error on focus", error);
	
									}
								
								}}
								onBlur={(e: any) => {
	
									try {
	
										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
										
									} catch (error: any) {
	
										console.error("Error on blur", error);
	
									}
								
								}}
								
							/>
						</Col>
						
					</Row>
					<Row
						style={{ marginTop: "0px" }}
						className="sh-addlesson-enter-lesson-name-page sh-addlesson-enter-lesson-name-page-margin-top"
					>
						<Col
							className="sh-add-lesson-typography-label-page"
						>
							<div className="type-con-div">
								<Typography style={{ color: theme.palette.text.primary }} className="sh-add-module-multi-description-label sh-add-lesson-marginTop">Module Price in USD <span style={{ color: theme?.palette?.error?.main }} >*</span></Typography>
							</div>
						</Col>
						<Col className="sh-add-lesson-input-label">
							<Form.Control
								className="sh-add-module-name-text-field text-field-width custom-placeholder"
								style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								id="outlined-basic"
								placeholder="Enter Module Price"
								/*
								 * label="Enter Module Price"
								 * variant="outlined"
								 */
								value={modulePriceInUSD}
								// fullWidth
								type="number"
								onChange={(e: any) => setModulePriceInUSD(e?.target?.value)}
								// size="small"
								color="secondary"
								onFocus={(e: any) => {

									try {
	
										const focusColor: string = theme.palette.secondary.main;
	
										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.fontSize = "13px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
										
									} catch (error: any) {
	
										console.error("Error on focus", error);
	
									}
								
								}}
								onBlur={(e: any) => {
	
									try {
	
										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
										
									} catch (error: any) {
	
										console.error("Error on blur", error);
	
									}
								
								}}
								
							/>
						</Col>
						
					</Row>
					
				</Col>
				
				<Col className="sh-add-lesson-width">
					<Row className="sh-add-lesson-uploadvideo">
							
						<Row className="box-shadow sh-add-lession-admin-dropzone-file-upload-title-con" style={{ backgroundColor: theme.palette.background.paper }}>

							<Row > <Typography style={{ color: theme.palette.text.primary }}>Upload Module Icon <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
							</Row>
							<Row className="sh-add-lesson-dropzone">
								<DropZone
									dropMedia={postMediaDrop}
									isUploadedMediaValid={isUploadedMediaValid}
									uploadPostMediaName={uploadPostMediaName}
									acceptFileType={config?.acceptedImageFormats}
									accept={config?.acceptedFilesImages}
									disabled={!selectedCourse}
									disabledMessage={"Please select the course"}
									style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
									// style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								/>
							</Row>
							<Row className="sh-add-lesson-upload-button" style={{ display: "flex", flexDirection: "column", width: "100%" }}>
								<Col style={{ minWidth: "425px" }}>
									{
												  fileDetails && isFileUploading &&
											<Progress.Line percent={uploadProgress} status={
												uploadProgress === 100 ? "success" : "active"
											}
											showInfo={true} strokeWidth={8} strokeColor={theme.palette.primary.main}/>
									}
								</Col>
								{/* <Col>
									{
												 fileDetails && isFileUploading &&
											<Progress.Line
												percent={uploadProgress}
												strokeColor={theme.palette.secondary.main}
												status={
													uploadProgress === 100 ? "success" : "active"
												} style={{ marginTop: "1em" }} />
									}
								</Col> */}
								<Col>
									<RButton
										onClick={() => handleUploadModuleIcon()}
										disabled={!selectedCourse || !isUploadedMediaValid || isFileUploading}
										/*
										 * style={{
										 * 	backgroundColor: !selectedCourse ?
										 * 		theme.palette.grey[400] : theme?.palette?.secondary.main,
										 * 	color: theme.palette.text.primary, float: "right" }}
										 */
										style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.text.primary,float: "right" }}

									>
									Upload
									</RButton>
								</Col>
							</Row>
						</Row>
					</Row>
					<Row className="sh-ad-lesson-Rbutton-cancel-add">
						<Col className="sh-ad-lesson-Rbutton-cancel-flex">
							<RButton
								/*
								 * style={{
								 * 	borderColor: theme.palette.primary.main,
								 * 	color      : theme.palette.text.primary
								 * }}
								 */
								style={{ color: theme.palette.text.primary, borderColor: theme.palette.secondary.main, backgroundColor: theme.palette.background.default }}

								className=" sh-add-lesson-cancel-button sh-add-lesson-cancel-border sh-module-intro-add-btn sh-add-cancel-margin"
								onClick={handleClose}
							>
              					Cancel
							</RButton>
							<RButton
								disabled={isFileUploading}
								style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.text.primary }}
								className="sh-module-intro-add-btn"
								onClick={handleAddOrUpdateModule}>
								{
									isEditModule ? "Update Module" : "Add Module"
								}
							</RButton>
						
						</Col>
					
					</Row>
					<Row className="sh-add-module-error-message">
						{
							isError &&
							<div className="">
								<Typography variant="subtitle1" sx={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop"> {errorMessage} </Typography>
							</div>
						}
					</Row>
				</Col>
			</Row>
			
		</Container>
	);

};

export default AddModulePage;