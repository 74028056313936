// material-ui
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { useDispatch } from "./../../store";
import { useNavigate } from "react-router-dom";

// project imports

// import useAuth from "./../../Hooks/useAuth";
import { openSnackbar } from "./../../store/slices/snackbar";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

// ========================|| FIREBASE - FORGOT PASSWORD ||======================== //

const AuthForgotPassword = ({ ...others }) => {

	const theme: any = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const [ status, setStatus ] = useState({ success: false });
	const [ submitting, setSubmitting ] = useState(false);
	const [ errors, setErrors ] = useState({
		submit  : "",
		email   : "",
		password: ""

	});
	const [ values, setValues ] = useState({
    	email: ""
	});

	// const { forgotPassword } = useAuth();

	const handleSubmit = async (event: any) => {

		try {

			event.preventDefault();

			setSubmitting(true);

			const error = validateEmail(values.email);

			if (error) {

				setErrors({ ...errors, email: error });
				setSubmitting(false);

				return;

			}

			await sendPasswordResetEmail(auth, values.email);

			setSubmitting(false);
			dispatch(
				openSnackbar({
					open   : true,
					message: "Check mail for reset password link",
					variant: "alert",
					alert  : {
						color: "success"
					},
					close: false
				})
			);
			setTimeout(() => {

				navigate("/login", { replace: true });
            
			}, 1500);
        
		} catch (err: any) {

			console.error(err);
			setErrors({ ...errors, submit: err.message });
			setSubmitting(false);
		
		}

	};

	const validateEmail = (value: string) => {

		let error;
		if (!value) {

			error = "Email is required";

		} else if (!/\S+@\S+\.\S+/.test(value)) {

			error = "Email is invalid";

		}

		return error;

	};

	const handleChange = (event: any) => {

		setValues({
			...values,
			[event.target.name]: event.target.value
		});

		if (errors.email) {

			setErrors({ ...errors, email: "" });
        
		}
    
	};

	return (
		<form noValidate onSubmit={handleSubmit} {...others}>
			<FormControl fullWidth error={Boolean(errors.email)} sx={{ ...theme.typography.customInput }}>
				<InputLabel htmlFor="outlined-adornment-email-forgot">Email Address</InputLabel>
				<OutlinedInput
					id="outlined-adornment-email-forgot"
					type="email"
					value={values.email}
					name="email"
					onChange={handleChange}
					label="Email Address"
					inputProps={{}}
				/>
				{errors.email &&
                <FormHelperText error id="standard-weight-helper-text-email-forgot">
                	{errors.email}
                </FormHelperText>
				}
			</FormControl>
			{errors.submit &&
            <Box sx={{ mt: 3 }}>
            	<FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
			}

			<Box sx={{ mt: 2 }}>
				<Button
					disableElevation
					disabled={submitting}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					color="secondary"
				>Send Mail</Button>
			</Box>
		</form>
	);

};

export default AuthForgotPassword;
