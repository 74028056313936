import PropTypes from "prop-types";
import React from "react";

// material-ui
import { Box } from "@mui/material";

// project import
import MainCard from "../../components/UI/cards/MainCard";

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, ...other }: any) =>
	<MainCard
		sx={{
			maxWidth    : { xs: 350, lg: 425 },
			borderRadius: "16px",
			margin      : { xs: 2.5, md: 3 },
			"& > *"     : {
				flexGrow : 1,
				flexBasis: "50%"
			}
		}}
		content={false}
		{...other}
	>
		<Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
	</MainCard>
;

AuthCardWrapper.propTypes = {
	children: PropTypes.node
};

export default AuthCardWrapper;
