import { Link } from "react-router-dom";
import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";

// project imports
import AuthWrapper1 from "./AuthWrapper1";
import AuthCardWrapper from "./AuthCardWrapper";
import AuthLogin from "./AuthLogin";
// import LoginSuccessful from "../../assets/animationJson/login-successful.json";
import appConfig from "../../config/appConfig";
import CustomLoader from "../../components/UI/cards/CustomLoader";

const Login = () => {

	
	const theme = useTheme();
	const [ loginSuccess, setLoginSuccess ] = useState(false);
	// const { isLoggedIn }: any = useAuth();
	const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
	const Routes = appConfig.routes;
	

	return <>{
		loginSuccess ? <div
			style={{
				minHeight     : "80vh",
				display       : "flex",
				justifyContent: "center",
				alignItems    : "center"
			}}
		>
			<CustomLoader />
		</div> : <AuthWrapper1 style={{ height: "100%" }}>
			<Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100%" }} style={{ height: "100%" }}>
				<Grid item xs={12} style={{ height: "100%" }}>
					<Grid container style={{ height: "100%" }} justifyContent="center" alignItems="center" sx={{ minHeight: "100%" }} className="sh-margin-zero">
						<Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
							<AuthCardWrapper>
								<Grid container spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={12}>
										<Grid
											container
											direction={matchDownSM ? "column-reverse" : "row"}
											alignItems="left"
											justifyContent="left"
										>
											<Grid>
												<Stack alignItems="left" justifyContent="center" spacing={1}>
													<Typography
														color={theme.palette.text.primary}
														gutterBottom
														variant={matchDownSM ? "h4" : "h3"}
													>Welcome to Soul Swara</Typography>
													<Typography
														variant="caption"
														fontSize="14px"
														textAlign={matchDownSM ? "center" : "inherit"}
													>Enter your credentials to continue</Typography>
												</Stack>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<AuthLogin setLoginSuccess={setLoginSuccess} />
									</Grid>
									<Grid item xs={12}>
										{/* <Divider /> */}
									</Grid>
									<Grid item xs={12}>
										<Grid item container direction="column" alignItems="center" xs={12}>
											<span>Don&apos;t have an account?&nbsp;
												<Typography
													component={Link}
													// onClick={() => navigate(Routes.register)}
													to={Routes.register}
													variant="subtitle1"
													sx={{
														textDecoration: "none",
														color         : theme.palette.secondary.main,
														"&:hover"     : { color: theme.palette.secondary.dark }
													}}
												>
														Sign up
												</Typography>
											</span>
												
										</Grid>
									</Grid>
								</Grid>
								{/* <Grid>
								<Lottie
									animationData={LoginAnimation}
									style={{ width: "100%", height: "100%" }}
								/>
							</Grid> */}
							</AuthCardWrapper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</AuthWrapper1>
	}</>;
	

};

export default Login;
