import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { IoIosMusicalNote } from "react-icons/io";
import { IoIosMusicalNotes } from "react-icons/io";


const CustomLoader = () => {

	const theme: any = useTheme();

	return (
		<Box
			sx={{
				// backgroundColor: theme?.palette?.background?.default
			}}
		>
			<motion.div
				style={{
					position      : "relative",
					width         : "100px",
					height        : "60px",
					margin        : "0 auto",
					display       : "flex",
					justifyContent: "center",
					alignItems    : "center"
				}}
			>
				{[ ...Array(3) ].map((_, i) =>
					<motion.div
						key={i}
						style={{
							position: "absolute",
							bottom  : "0px",
							left    : `${0 + i * 30}px`
						}}
						animate={{
							y: [
								0, -15, 0
							],
							opacity: [
								1, 0.5, 1
							]
						}}
						transition={{
							duration: 0.8,
							repeat  : Infinity,
							delay   : i * 0.3,
							ease    : "easeInOut"
						}}
					>
						{i % 2 === 0 ? <IoIosMusicalNote color={theme.palette.primary.main} size={30} /> : <IoIosMusicalNotes size={30} color={theme.palette.secondary.main} />}
					</motion.div>
				)}
			</motion.div>
		</Box>
	);

};

export default CustomLoader;