import React, { useState, useEffect, useContext } from "react";

import {
	Grid,
	Button,
	useTheme,
	Container,
	useMediaQuery,
	Typography
} from "@mui/material";

import { Col, Row, Form } from "react-bootstrap";
import Select from "../../../components/UI/Select";
import CustomLoader from "../../../components/UI/cards/CustomLoader";
import UserContext from "../../../context/AuthContext";
import countryConfig from "../../../config/countryConfig";
import stateConfig from "../../../config/stateConfig";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { useDispatch } from "../../../store";
import { setUserAddress, setUser } from "../../../store/slices/userSlice";
import toast from "react-hot-toast";
import MainButton from "../../../components/UI/extend/MainButton";
import config from "../../../config/appConfig";

import { useLocation, useNavigate } from "react-router-dom";


const UserAddressUpdate = () => {

	const { userDetails, setUserDetails } = useContext(UserContext);
	const dispatch = useDispatch();
	const theme = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();
	const locationState = useLocation().state;
	const moduleData = JSON.parse(locationState?.moduleData || "{}");

	const [ isUserDataLoading, setIsUserDataLoading ] = useState(true);
	const [ billingFirstName, setBillingFirstName ] = useState("");
	const [ billingLastName, setBillingLastName ] = useState("");
	const [ companyName, setCompanyName ] = useState("");
	const [ addressOne, setAddressOne ] = useState("");
	const [ addressTwo, setAddressTwo ] = useState("");
	const [ cityName, setCityName ] = useState("");
	const [ postalCode, setPostalCode ] = useState("");
	const [ stateName, setStateName ] = useState<any>([]);
	const [ countryName, setCountryName ] = useState<any>([]);
	const [ billingPhoneNo, setBillingPhoneNo ] = useState("");
	const countries: any = countryConfig.allCountries;
	const updatedCountries = countries?.map((country: any) => ({
	  label: country.name,
	  value: country.name,
	  ...country
	}));

	const [ stateOptions, setStateOptions ] = useState<any>([]);


	useEffect(() => {

		if (isUserDataLoading) {

			if (userDetails) {

				setBillingFirstName(userDetails.firstName);
				setBillingLastName(userDetails.lastName);
				setCompanyName(userDetails.companyName);
				setAddressOne(userDetails.userAddress.addressOne);
				setAddressTwo(userDetails.userAddress.addressTwo);
				setCityName(userDetails.userAddress.cityName);
				setPostalCode(userDetails.userAddress.postalCode);
				setBillingPhoneNo(userDetails.userAddress.phoneNo);
				const selectedCountry = updatedCountries.find((country: any) => country.label === userDetails.userAddress.countryName);
				setCountryName(selectedCountry);
				const stateOptions = updatedStates(selectedCountry?.isoCode);
				setStateOptions(stateOptions);
				setStateName(stateOptions.find((state: any) => state.value === userDetails?.userAddress?.stateName));
				setIsUserDataLoading(false);

			}

		}

	} , [ userDetails ]);

	const updatedStates = (countryId: any) => {

		const statesOptions = [
		  {
				label: "-None-",
				value: ""
		  }
		];
	
		if (countryId) {
	
		  stateConfig.countryState[countryId].map((state: any) => {
	
				statesOptions.push({
			  label: state.name,
			  value: state.name,
			  ...state
				});
		  
		  });
	
		}
		
		return statesOptions;
	  
	};

    
	const handleAddressInfoSaveChanges = () => {

		if (billingPhoneNo == "" || billingFirstName == "" || billingLastName == "" || addressOne == "" || cityName == "" || postalCode == "" || stateName == "" || countryName == "" || !billingFirstName || !billingLastName || !addressOne || !cityName || !postalCode || !stateName?.value || !countryName?.value) {

			toast.error("Please fill in all the required fields");

			return;

		}

		setIsUserDataLoading(true);

		const payLoad = {

			"firstName"  : billingFirstName,
			"lastName"   : billingLastName,
			"userAddress": {
				"companyName": companyName,
				"addressOne" : addressOne,
				"addressTwo" : addressTwo,
				"cityName"   : cityName,
				"postalCode" : postalCode,
				"stateName"  : stateName?.value,
				"countryName": countryName?.value,
				"phoneNo"    : billingPhoneNo
			}

		};
		ApiRequest("post", APIUrl.updateUserAddress, payLoad).then((response: any) => {

			if (response?.data) {

				console.info(response?.data);
				dispatch(setUser({ user: response?.data }));
				setUserDetails(response?.data);
				dispatch(setUserAddress({ userAddress: response?.data?.userAddress }));
				sessionStorage.setItem("userDetails", JSON.stringify(response?.data));
				navigate(config.routes.moduleIntro, { state: { moduleData: JSON.stringify(moduleData) } });

			}


		}).catch((error: any) => {

			console.error(error);
			setIsUserDataLoading(false);

		});

	};

	const formData = [
		{
			"title"      : "First Name",
			"value"      : billingFirstName,
			"onChange"   : setBillingFirstName,
			"placeholder": "Enter the First Name",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Last Name",
			"value"      : billingLastName,
			"onChange"   : setBillingLastName,
			"placeholder": "Enter the Last Name",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Company",
			"value"      : companyName,
			"onChange"   : setCompanyName,
			"placeholder": "Enter the Company Name",
			"isSelect"   : false,
			"isMandatory": false

		},
		{
			"title"      : "Address 1",
			"value"      : addressOne,
			"onChange"   : setAddressOne,
			"placeholder": "Enter the Address",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Address 2",
			"value"      : addressTwo,
			"onChange"   : setAddressTwo,
			"placeholder": "Enter the Address",
			"isSelect"   : false,
			"isMandatory": false
		},
		{
			"title"      : "City",
			"value"      : cityName,
			"onChange"   : setCityName,
			"placeholder": "Enter the City Name",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Postal Code",
			"value"      : postalCode,
			"onChange"   : setPostalCode,
			"placeholder": "Enter the Postal Code",
			"isSelect"   : false,
			"isMandatory": true
		},
		{
			"title"      : "Country",
			"options"    : updatedCountries,
			"value"      : countryName,
			"onChange"   : setCountryName,
			"placeholder": "Enter the Country Name",
			"isSelect"   : true,
			"isMandatory": true
		},
		{
			"title"      : "State",
			"options"    : stateOptions,
			"value"      : stateName,
			"onChange"   : setStateName,
			"placeholder": "Enter the State Name",
			"isSelect"   : true,
			"isMandatory": true
		},
		
		{
			"title"      : "Phone Number",
			"value"      : billingPhoneNo,
			"onChange"   : setBillingPhoneNo,
			"placeholder": "Enter the Phone Number",
			"isSelect"   : false,
			"isMandatory": true
		}
	];

	return (
		<> {isUserDataLoading ? <div
			style={{
				minHeight     : "80vh",
				display       : "flex",
				justifyContent: "center",
				alignItems    : "center"
			}}
		><CustomLoader />
		</div> : <Container>

			<Row className="sh-level-page-container">
				<Col className="sh-level-page-left-side-container">
					<Typography variant="h4" gutterBottom className="" style={{ paddingBottom: "1em" }}>
							Please update your address details here.
					</Typography>
					<Typography variant="h5" gutterBottom className="" style={{ paddingBottom: "1em" }}>
							Please fill all the required <span style={{ color: "red" }}>*&nbsp;</span> fields to continue.
					</Typography>
					{
						formData.map((data: any, index: number) => {

							if (data.isSelect) {

								return <Row
									key={index}
									style={{ display: "flex", paddingBlock: "1em" }}>
									<Col md={3} lg={3} xl={3} sm={12} xs={12}>
										<Typography variant="subtitle1">{data.title}&nbsp;
											{data.isMandatory && <span style={{ color: "red" }}>*</span>}
										</Typography>
									</Col>
									<Col md={6} lg={6} xl={6} sm={12} xs={12}>
										<Select
											options={data.title === "Country" ? updatedCountries : stateOptions}
											value={data.value}
											handleChange={(e: any) => {

												if (data.title === "Country") {

													data.onChange(e);
													setStateName([]);

													setStateOptions(updatedStates(e.isoCode));
													
												} else {
														
													data.onChange(e);

												}
												
											}}
											placeholder={data.placeholder}
											style={{ width: "100%" }}
										/>
									</Col>
										
								</Row>;
								
							} else {

								return <Row
									key={index}
									style={{ display: "flex", paddingBlock: "1em" }}
								>
									<Col md={3} lg={3} xl={3} sm={12} xs={12}>
										<Typography variant="subtitle1">{data.title}&nbsp;
											{data.isMandatory && <span style={{ color: "red" }}>*</span>}</Typography>
									</Col>
									<Col md={6} lg={6} xl={6} sm={12} xs={12}>
										<Form.Control
											id="outlined-basic"
											style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
											color="secondary"
											placeholder={data.placeholder}
											value={data.value}
											onChange={e => data.onChange(e.target.value)}
											onFocus={(e: any) => {
	
												const focusColor: string = theme.palette.secondary.main;
	
												e.target.style.borderColor = focusColor;
												e.target.style.borderWidth = "1.5px";
												e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
	
											}}
											onBlur={(e: any) => {
	
												e.target.style.borderColor = "";
												e.target.style.boxShadow = "";
	
											}}
													
										/>
										<style>
											{` #outlined-basic::placeholder { color: ${theme.palette.text.secondary}; /* Change placeholder color */ opacity: 1; font-size: .8em;}`}
										</style>
									</Col>
								</Row>;

							}

						
						})
					}
				</Col>
			</Row>
			<Grid item xs={12} className="intro-page-next-btn-con">
				<MainButton
					type="scale"
					direction="bottom"
					offset={matchesXS ? 0 : 20}
					scale={0.9}>
					<Button
                        	variant="contained"
                        	color="primary"
                        	sx={{
                        		borderRadius: "50px",
                        		fontSize    : "1.2rem"
                        	}}
                        	size={matchesXS ? "small" : "medium"}
                        	onClick={handleAddressInfoSaveChanges}>
                                Update Address
					</Button>
				</MainButton>
			</Grid>
		</Container>
		}
		</>
	);

};

export default UserAddressUpdate;