import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import {
	AppBar,
	Box,
	// Container,
	CssBaseline,
	Toolbar,
	useMediaQuery
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// project imports

// import Breadcrumbs from "../../UI/extend/Breadcrumbs";
import Header from "./AppHeader";
// import HorizontalBar from "./HorizontalBar";

import useConfig from "../../../Hooks/useConfig";
import LAYOUT_CONST from "../../../constant";
import { useDispatch, useSelector } from "../../../store";
import { drawerWidth } from "../../../store/constant";
import { openDrawer } from "../../../store/slices/menu";

// assets

// import { IconChevronRight } from "@tabler/icons";

interface MainProps {
	theme: any;
	open: boolean;
	layout: string;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop: any) => prop !== "open" })(
	({ theme, open, layout }: MainProps) => ({
		...theme.typography.mainContent,
		backgroundColor: theme.palette.background.default,
		position       : "relative",
		zIndex         : 2,
		// maxHeight      : "87vh",
		minHeight      : "87vh",
		overflowY      : "auto",
		marginRight    : 0,
		...!open && {
			borderBottomLeftRadius : 0,
			borderBottomRightRadius: 0,
			transition             : theme.transitions.create("margin", {
				easing  : theme.transitions.easing.sharp,
				duration: theme.transitions.duration.shorter + 200
			}),
			[theme.breakpoints.up("md")]: {
				/*
				 * marginLeft:
				 * 	layout === LAYOUT_CONST.VERTICAL_LAYOUT
				 * 		? -(drawerWidth - 72)
				 * 		: "20px",
				 */
				marginLeft: "20px",
				width     : `calc(100% - ${drawerWidth}px)`,
				marginTop : 88
				// marginTop : layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
			},
			[theme.breakpoints.down("md")]: {
				marginLeft: "20px",
				width     : `calc(100% - ${drawerWidth}px)`,
				padding   : "16px",
				marginTop : 88
			},
			[theme.breakpoints.down("sm")]: {
				marginLeft : "10px",
				width      : `calc(100% - ${drawerWidth}px)`,
				padding    : "16px",
				marginRight: "10px",
				marginTop  : 88
			}
		},
		...open && {
			transition: theme.transitions.create("margin", {
				easing  : theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.shorter + 200
			}),
			marginLeft                  : layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
			marginTop                   : layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
			borderBottomLeftRadius      : 0,
			borderBottomRightRadius     : 0,
			width                       : `calc(100% - ${drawerWidth}px)`,
			[theme.breakpoints.up("md")]: {
				marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
			},
			[theme.breakpoints.down("md")]: {
				marginLeft: "20px",
				marginTop : 88
			},
			[theme.breakpoints.down("sm")]: {
				marginLeft: "10px",
				marginTop : 88
			}
		}
	})
);

// Main layout component

const MinimalLayout = () => {

	const theme = useTheme();
	const location = useLocation();
	const pathName = location.pathname;
	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state: any) => state.menu);
	const { drawerType, layout } = useConfig();

	const [ open, setOpen ] = useState<boolean>(
		drawerType === LAYOUT_CONST.DEFAULT_DRAWER && drawerOpen ? true : false
	);


	const handleDrawerToggle = () => {

		setOpen(!open);
		dispatch(openDrawer(!open));

	};

	useEffect(() => {

		setOpen(false);
		dispatch(openDrawer(false));

	}, []);
	// useEffect(() => {

	//   if (matchDownMd) {

	/*
	 *     setOpen(true);
	 *     dispatch(openDrawer(true));
	 */

	//   }

	// }, [ matchDownMd ]);

	useEffect(() => {

		if (open !== drawerOpen) setOpen(drawerOpen);

	}, [ drawerOpen ]);

	const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

	const header = useMemo(
		() =>
			<Toolbar sx={{ p: condition ? "10px" : "16px" }}>
				<Header handleDrawerToggle={handleDrawerToggle} />
			</Toolbar>
		,
		[
			layout, matchDownMd, open
		]
	);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			{/* header */}
			<AppBar
				position="fixed"
				elevation={0}
				sx={{
					minHeight : "10vh",
					maxHeight : "10vh",
					bgcolor   : "#00000033",
					transition: drawerOpen ? theme.transitions.create("width") : "none"
				}}
			>
				{header}
			</AppBar>

			{/* horizontal menu-list bar */}
			{/* {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd &&
				<HorizontalBar />
			} */}

			{/* drawer */}
			{/* {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) &&
				<Sidebar
					open={open}
					handleDrawerToggle={handleDrawerToggle}
				/>
			} */}

			{/* main content */}
			<Main style={ pathName == "/" ? { padding: "0px", margin: "0px", minHeight: "100vh" } : { padding: "0px", minHeight: "87vh" }} theme={theme} open={drawerOpen} layout={layout}>
				{/* <Container maxWidth={container ? "lg" : false} sx={{ height: "100%" }}> */}
				{/* breadcrumb */}
				{/* <Breadcrumbs
						separator={IconChevronRight}
						navigation={navigation}
						icon
						title
						rightAlign
					/> */}
				<Outlet />
				{/* </Container> */}
			</Main>
		</Box>
	);

};

export default MinimalLayout;
