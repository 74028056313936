import React from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

// project imports
import AuthWrapper1 from "./AuthWrapper1";
import AuthCardWrapper from "./AuthCardWrapper";
// import Logo from "ui-component/Logo";
import AuthForgotPassword from "./AuthForgotPassword";
// import BackgroundPattern1 from "ui-component/cards/BackgroundPattern1";

// import AuthSlider from "ui-component/cards/AuthSlider";

/*
 * assets
 * import AuthMultiCard from "assets/images/auth/auth-forgot-pass-multi-card.svg";
 */

/*
 * styles
 * const PurpleWrapper = styled("span")(({ theme }) => ({
 * 	"&:before": {
 * 		content                       : "\"\"",
 * 		position                      : "absolute",
 * 		top                           : "18%",
 * 		left                          : "18%",
 * 		width                         : 515,
 * 		height                        : 470,
 * 		backgroundImage               : `url(${AuthMultiCard})`,
 * 		backgroundRepeat              : "no-repeat",
 * 		backgroundPosition            : "center",
 * 		animation                     : "15s wings ease-in-out infinite",
 * 		animationDelay                : "1s",
 * 		[theme.breakpoints.down("xl")]: {
 * 			top           : "10%",
 * 			left          : "6%",
 * 			backgroundSize: 450
 * 		}
 * 	}
 * }));
 */

/*
 * carousel items
 * const items = [
 * 	{
 * 		title      : "Powerful and easy to use multipurpose theme.",
 * 		description: "Powerful and easy to use multipurpose theme"
 * 	},
 * 	{
 * 		title      : "Power of React with Material UI",
 * 		description: "Powerful and easy to use multipurpose theme"
 * 	},
 * 	{
 * 		title      : "Power of React with Material UI",
 * 		description: "Powerful and easy to use multipurpose theme"
 * 	}
 * ];
 */

// ============================|| AUTH1 - FORGOT PASSWORD ||============================ //

const ForgotPassword = () => {

	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<AuthWrapper1>
			<Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: "100%" }} style={{ height: "100%" }}>
				<Grid item container justifyContent="center" md={6} lg={7} sx={{ my: 3 }}>
					<AuthCardWrapper>
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={12}>
								<Grid
									container
									direction={matchDownSM ? "column-reverse" : "row"}
									alignItems={matchDownSM ? "center" : "inherit"}
									justifyContent={matchDownSM ? "center" : "space-between"}
								>
									<Grid item>
										<Stack
											justifyContent={matchDownSM ? "center" : "flex-start"}
											textAlign={matchDownSM ? "center" : "inherit"}
										>
											<Typography
												color={theme.palette.secondary.main}
												gutterBottom
												variant={matchDownSM ? "h3" : "h2"}
											>
                                                Forgot password?
											</Typography>
											<Typography color="textPrimary" gutterBottom variant="h4">
                                                Enter credentials to continue
											</Typography>
										</Stack>
									</Grid>
									{/* <Grid item sx={{ mb: { xs: 3, sm: 0 } }}>
										<Link to="#" aria-label="theme-logo">
											<Logo />
										</Link>
									</Grid> */}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Stack direction="row" justifyContent={matchDownSM ? "center" : "flex-start"}>
									<Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? "center" : "inherit"}>
                                        Enter your email address below and we&apos;ll send you password reset OTP.
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<AuthForgotPassword />
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid item container direction="column" alignItems="flex-end" xs={12}>
									<Typography
										component={Link}
										to="/login"
										variant="subtitle1"
										sx={{ textDecoration: "none" }}
									>
                                        Already have an account?
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</AuthCardWrapper>
				</Grid>
			</Grid>
		</AuthWrapper1>
	);

};

export default ForgotPassword;
