import config from "../config/appConfig";

import React from "react";
import GuestMode from "../Utils/RouteSentry/GuestMode";
import MinimalLayout from "../components/Layout/MinimalLayout";
import NavMotion from "../components/Layout/NavMotion";
// import Loading from "../components/Loader/Loading";
import TermsAndConditions from "../views/LandingPage/TermsAndConditions";
import PrivacyPolicy from "../views/LandingPage/PrivacyPolicy";
import RefundPolicy from "../views/LandingPage/RefundPolicy";
import LandingPage from "../views/LandingPage/LandingPage";
import Login from "../views/Auth/login";
import Register from "../views/Auth/Register/Register";
import ForgotPassword from "../views/Auth/ForgotPassword";

// const LandingPage = Loading(lazy(() => import("../views/LandingPage/LandingPage")));

// const Login = Loading(lazy(() => import("../views/Auth/login")));

// const Register = Loading(lazy(() => import("../views/Auth/Register/Register")));

// const ForgotPassword = Loading(lazy(() => import("../views/Auth/ForgotPassword")));

const Routes = config.routes;

const publicRoutes: any = {
	path: "/",
	element:
		<NavMotion>
			<GuestMode>
				<MinimalLayout />
			</GuestMode>
		</NavMotion>
	,
	children: [
		{
			path   : "/",
			element: <LandingPage />
		},
		{
			path   : Routes.login,
			element: <Login />
		},
		{
			path   : Routes.register,
			element: <Register />
		},
		{
			path   : Routes.termsAndConditions,
			element: <TermsAndConditions />
		},
		{
			path   : Routes.refundPolicy,
			element: <RefundPolicy />
		},
		{
			path   : Routes.privacyPolicy,
			element: <PrivacyPolicy />
		},
		{
			path   : Routes.forgotPassword,
			element: <ForgotPassword />
		}
	]
};
export default publicRoutes;
