import React, { useState, useEffect, useContext } from "react";
// import { useTheme } from "@mui/material/styles";
import {
	Grid,
	Button,
	useTheme,
	Container,
	useMediaQuery
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// components
import SkillLevel from "./SkillLevel";
import MainButton from "../../../components/UI/extend/MainButton";
import CustomLoader from "../../../components/UI/cards/CustomLoader";
// Store
import { useDispatch, useSelector } from "../../../store";
import { setUser, setUserAddress } from "../../../store/slices/userSlice";
// Api
import { APIUrl } from "../../../api/endPoints";
import ApiRequest from "../../../api/ApiRequest";
// Config
import config from "../../../config/appConfig";
import { auth } from "../../../firebase";
import UserContext from "../../../context/AuthContext";

const IntroPage = () => {

	const theme = useTheme();
	const user = auth.currentUser;
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const { id } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { setUserDetails } = useContext(UserContext);

	const [ isUserDataLoading, setIsUserDataLoading ] = useState(true);
	const [ selectedSkillLevel, setSelectedSkillLevel ] = useState<any>("");
	const [ isSkillLevelSelected, setIsSkillLevelSelected ] = useState<any>(null);
	
	let isDataFetched = false;

	useEffect(() => {

		if (isDataFetched && id !== "") return;
		isDataFetched = true;
		fetchUserDetails();

	}, [ user ]);

	useEffect(() => {

		if (isSkillLevelSelected == true) {

			navigate(config.routes.course);

		}

	}, [ isSkillLevelSelected ]);

	const fetchUserDetails = () => {

		setIsUserDataLoading(true);
		ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {

			setUserDetails(response);
			dispatch(setUser({ user: response }));
			dispatch(setUserAddress({ userAddress: response?.userAddress }));
			setIsSkillLevelSelected(response?.skillLevel !== "");
			sessionStorage.setItem("userDetails", JSON.stringify(response));
			setIsUserDataLoading(false);

		}).catch((error: any) => {

			navigate(config.routes.landingHome);
			console.error(error);
			setIsUserDataLoading(false);
			

		});

	};


	const handleContinue = () => {

		const payLoad = {
			"skillLevel": selectedSkillLevel
		};

		setIsUserDataLoading(true);

		ApiRequest("post", APIUrl.updateUserSkill, payLoad).then(() => {

			navigate(config.routes.course, { state: { fromIntro: true, skill: selectedSkillLevel } });
			setIsUserDataLoading(false);

		}).catch(error => {

			console.error(error);
			setIsUserDataLoading(false);

		});

	};

	return <> {isUserDataLoading ? <div
		style={{
			minHeight     : "80vh",
			display       : "flex",
			justifyContent: "center",
			alignItems    : "center"
		}}
	><CustomLoader /></div> :
		<Container>
			{
				!isSkillLevelSelected && <SkillLevel setSelectedSkillLevel={setSelectedSkillLevel} />
			}
			
			<Grid item xs={12} className="intro-page-next-btn-con">
				<MainButton
					type="scale"
					direction="bottom"
					offset={matchesXS ? 0 : 20}
					scale={0.9}>
					{
						!isSkillLevelSelected && <Button
							variant="contained"
							color="primary"
							sx={{
								borderRadius: "50px",
								fontSize    : "1.2rem"
							}}
							size={matchesXS ? "small" : "medium"}
							onClick={handleContinue}>Continue</Button>
					}
				</MainButton>
			</Grid>
		</Container>
	}
	</>

	;

};

export default IntroPage;
